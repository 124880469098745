<template>
  <div class="content">
    <!-- contentWeb -->
    <div class="web">
      <div class="contentWeb1">
        <p>茶产业数字化转型解决方案</p>
        <div class="gang"></div>
        <img src="../../../assets/img/contentWeb1.png" alt="" />
      </div>
      <div class="contentWeb2"></div>
    </div>
    <!-- contentWeb -->
    <div class="pc">
      <!-- 1 -->
      <div class="content1">
        <p>茶产业数字化转型解决方案</p>
        <div class="gang"></div>
        <img src="../../../assets/img/content1.png" alt="" />
      </div>
      <!-- 1 -->
      <!-- 2 -->
      <div class="content2"></div>
      <!-- 2 -->
      <!-- <div class="new3">
        <div class="new3-box">
          <div class="gang"></div>
          <p>数字化茶园</p>
          <span>
            基于新一代3S空间技术、物联传感、大数据、智能控制等技术，建设数字茶园一张图，全面掌握茶园地理分布、茶树环境监测、园区生态管理。
          </span>
          <a @click="mao1Fn('contentArrTea4')"></a>
        </div>
        <img src="@/assets/img/content-new3.png" alt="" class="img1" />
      </div> -->
      <!-- 2 -->
      <!-- 3 -->
      <div class="content3">
        <img src="@/assets/img/content3.png" alt="" class="img1" />
        <div class="content3-box">
          <div class="gang"></div>
          <p>RFID防伪溯源</p>
          <span>芯片级安全，轻松实现品质产品防伪溯源。通过手机“靠一靠”防伪查询、源头追溯，品牌增信、营销分享。助力产品品牌升级。</span>
          <a @click="mao1Fn('contentArrTea0')"></a>
        </div>
      </div>
      <!-- 3 -->
      <!-- 4 -->
      <div class="content4">
        <div class="content4-box">
          <div class="gang"></div>
          <p>数字化茶仓</p>
          <span>综合运用大数据分析、物联网技术、区块链技术，对茶叶的入仓存储、后期藏养、溯源监管等进行全流程的数字化管理和智能化控制。</span>
          <a @click="mao1Fn('contentArrTea5')"></a>
        </div>
        <img src="@/assets/img/content4.png" alt="" class="img1" />
      </div>
      <!-- 4 -->
      <!-- 5 -->
      <div class="content5">
        <div class="content5-box">
          <div class="gang"></div>
          <p>数字化订货</p>
          <span>实现总部与各级经销商、零售门店之间实时高效的订货、收货、发货管理，提供多种订货、钱包、返点策略，提供清晰明了的帐款管理功能。</span>
          <a @click="mao1Fn('contentArrTea1')"></a>
        </div>
        <img src="@/assets/img/content5.png" alt="" class="img1" />
      </div>
      <!-- 5 -->
      <!-- 6 -->
      <div class="content6">
        <div class="content6-box">
          <div class="gang"></div>
          <p>数字化门店</p>
          <span>轻松实现门店线上、线下全面经营管理。使用一部手机管门店，满足收款结算、收发货盘点、会员管理、进销存报表、线上营销推广一体化服务。</span>
          <a @click="mao1Fn('contentArrTea2')"></a>
        </div>
        <img src="@/assets/img/content6.png" alt="" class="img1" />
      </div>
      <!-- 6 -->
      <!-- 7 -->
      <div class="content7">
        <img src="@/assets/img/content7.png" alt="" class="img1" />
        <div class="content7-box">
          <div class="gang"></div>
          <p>数字化渠道</p>
          <span>基于RFID、二维码提供收发货数字化管理，规范渠道经营商模式，清楚了解每件商品的来源去向，实现窜货防控。</span>
          <a @click="mao1Fn('contentArrTea3')"></a>
        </div>
      </div>
      <!-- 7 -->
      <!-- 8 -->
      <div class="content8">
        <img src="@/assets/img/content8.png" alt="" class="img1" />
        <div class="content8-box">
          <div class="gang"></div>
          <p>全场景可视化BI驾驶舱</p>
          <span>每一款产品被赋予唯一“身份证”</span>
          <a href="#"></a>
        </div>
      </div>
      <!-- 8 -->
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    mao1Fn(id) {
      localStorage.setItem("anchorId", id);
      this.$router.push({
        name: "Tea", //跳转页面的name,如果目标位置内容是组件则name是父组件的name
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .content1 {
    display: none;
  }
  .content2 {
    display: none;
  }
  .contentWeb1 {
    display: block;
    margin: auto;
    background-color: #f1f5fd;
    font-family: PingFang SC-Medium, PingFang SC;
    overflow: hidden;
    position: relative;
    p {
      height: 1.1rem;
      font-size: 0.67rem;
      font-weight: 500;
      color: #093c7f;
      line-height: 0.75rem;
      margin-top: 1.19rem;
      margin-left: 6.29rem;
    }
    .gang {
      width: 8.56rem;
      height: 0.1rem;
      background: #093c7f;
      position: absolute;
      left: 5.8rem;
    }
    img {
      margin: auto;
      width: 17rem;
      height: 7rem;
      margin: 0.26rem 1.2rem;
    }
  }
  .contentWeb2 {
    height: 9.14rem;
    background: url(../../../assets/img/contentWeb2.png) no-repeat;
    background-size: cover; /* 让背景图基于容器大小伸缩 */
  }
  .new3 {
    height: 9.14rem;
    position: relative;
    overflow: hidden;
    background: url(../../../assets/img/content9.0.jpg) no-repeat;
    background-size: cover; /* 让背景图基于容器大小伸缩 */
    .img1 {
      width: 7rem;
      height: 7rem;
      position: absolute;
      top: 1rem;
      right: 0.46rem;
    }
    .new3-box {
      position: absolute;
      top: 1.8rem;
      left: 1.68rem;
      a {
        display: block;
        width: 4rem;
        height: 0.8rem;
        margin-top: 0.3rem;
        background: url(../../../assets/img/Component5.png) no-repeat;
        background-size: cover; /* 让背景图基于容器大小伸缩 */
      }
      p {
        font-size: 0.67rem;
        height: 0.84rem;
        font-weight: 800;
        color: #093c7f;
      }
      span {
        display: block;
        width: 9.2rem;
        font-size: 0.41rem;
        font-weight: 400;
        color: #093c7f;
      }
      .gang {
        width: 0.8rem;
        height: 0.1rem;
        background: #093c7f;
      }
    }
  }
  .content3 {
    height: 9rem;
    overflow: hidden;
    position: relative;
    margin: auto;
    .img1 {
      width: 7rem;
      height: 4.5rem;
      margin-top: 1.82rem;
      margin-left: 1.68rem;
    }
    .content3-box {
      position: absolute;
      top: 2rem;
      right: 0.46rem;
      a {
        display: block;
        width: 4rem;
        height: 0.8rem;
        margin-top: 0.3rem;
        background: url(../../../assets/img/Component5.png) no-repeat;
        background-size: cover; /* 让背景图基于容器大小伸缩 */
      }
      p {
        font-size: 0.67rem;
        height: 0.84rem;
        font-weight: 800;
        color: #093c7f;
      }
      span {
        display: block;
        width: 9.2rem;
        font-size: 0.41rem;
        font-weight: 400;
        color: #093c7f;
      }
      .gang {
        width: 0.8rem;
        height: 0.1rem;
        background: #093c7f;
      }
    }

    background: url(../../../assets/img/content3.0.jpg) no-repeat;
    background-size: cover; /* 让背景图基于容器大小伸缩 */
  }
  .content4 {
    height: 9rem;
    position: relative;
    .img1 {
      position: absolute;
      width: 7rem;
      height: 6.1rem;
      top: 1.32rem;
      right: 0.46rem;
    }
    .content4-box {
      position: absolute;
      top: 2rem;
      left: 1.68rem;
      a {
        display: block;
        width: 4rem;
        height: 0.8rem;
        background-color: red;
        margin-top: 0.3rem;
        background: url(../../../assets/img/Component5.png) no-repeat;
        background-size: cover; /* 让背景图基于容器大小伸缩 */
      }
      p {
        font-size: 0.67rem;
        height: 0.84rem;
        font-weight: 800;
        color: #093c7f;
      }
      span {
        display: block;
        width: 9.2rem;
        font-size: 0.41rem;
        font-weight: 400;
        color: #093c7f;
      }
      .gang {
        width: 0.8rem;
        height: 0.1rem;
        background: #093c7f;
      }
    }
    background: url(../../../assets/img/content4.0.jpg) no-repeat;
    background-size: cover; /* 让背景图基于容器大小伸缩 */
  }
  .content5 {
    height: 9rem;
    position: relative;
    overflow: hidden;
    .img1 {
      width: 7rem;
      height: 4.2rem;
      margin-top: 2.5rem;
      margin-left: 1.35rem;
    }
    .content5-box {
      position: absolute;
      top: 1.8rem;
      right: 0.46rem;
      a {
        display: block;
        width: 4rem;
        height: 0.8rem;
        margin-top: 0.3rem;
        background: url(../../../assets/img/Component5.png) no-repeat;
        background-size: cover; /* 让背景图基于容器大小伸缩 */
      }
      p {
        font-size: 0.67rem;
        height: 0.84rem;
        font-weight: 800;
        color: #093c7f;
      }
      span {
        display: block;
        width: 9.2rem;
        font-size: 0.41rem;
        font-weight: 400;
        color: #093c7f;
      }
      .gang {
        width: 0.8rem;
        height: 0.1rem;
        background: #093c7f;
      }
    }
    background: url(../../../assets/img/content5.0.jpg);
    background-size: cover; /* 让背景图基于容器大小伸缩 */
  }
  .content6 {
    height: 9rem;
    position: relative;
    overflow: hidden;
    .img1 {
      width: 7rem;
      height: 4rem;
      position: absolute;
      top: 2.17rem;
      right: 0.65rem;
    }
    .content6-box {
      position: absolute;
      top: 1.8rem;
      left: 1.68rem;
      a {
        display: block;
        width: 4rem;
        height: 0.8rem;
        margin-top: 0.3rem;
        background: url(../../../assets/img/Component5.png) no-repeat;
        background-size: cover; /* 让背景图基于容器大小伸缩 */
      }
      p {
        font-size: 0.67rem;
        height: 0.84rem;
        font-weight: 800;
        color: #093c7f;
      }
      span {
        display: block;
        width: 9.2rem;
        font-size: 0.41rem;
        font-weight: 400;
        color: #093c7f;
      }
      .gang {
        width: 0.8rem;
        height: 0.1rem;
        background: #093c7f;
      }
    }
    background: url(../../../assets/img/content6.0.jpg) no-repeat;
    background-size: cover; /* 让背景图基于容器大小伸缩 */
  }
  .content7 {
    height: 9rem;
    position: relative;
    overflow: hidden;
    .img1 {
      width: 7rem;
      height: 4.2rem;
      margin-top: 2.5rem;
      margin-left: 1.35rem;
    }
    .content7-box {
      position: absolute;
      top: 1.8rem;
      right: 0.46rem;
      a {
        display: block;
        width: 4rem;
        height: 0.8rem;
        margin-top: 0.3rem;
        background: url(../../../assets/img/Component5.png) no-repeat;
        background-size: cover; /* 让背景图基于容器大小伸缩 */
      }
      p {
        font-size: 0.67rem;
        height: 0.84rem;
        font-weight: 800;
        color: #093c7f;
      }
      span {
        display: block;
        width: 9.2rem;
        font-size: 0.41rem;
        font-weight: 400;
        color: #093c7f;
      }
      .gang {
        width: 0.8rem;
        height: 0.1rem;
        background: #093c7f;
      }
    }
    background: url(../../../assets/img/content7.0.jpg) no-repeat;
    background-size: cover; /* 让背景图基于容器大小伸缩 */
  }
  .content8 {
    height: 9rem;
    position: relative;
    overflow: hidden;
    .img1 {
      width: 7rem;
      height: 5.74rem;
      position: absolute;
      top: 1.34rem;
      right: 1.33rem;
    }
    .content8-box {
      position: absolute;
      top: 2.5rem;
      left: 1.68rem;
      a {
        display: block;
        width: 4rem;
        height: 0.8rem;
        margin-top: 0.3rem;
        background: url(../../../assets/img/Component5.png) no-repeat;
        background-size: cover; /* 让背景图基于容器大小伸缩 */
      }
      p {
        font-size: 0.67rem;
        height: 0.84rem;
        font-weight: 800;
        color: #093c7f;
      }
      span {
        display: block;
        width: 9.2rem;
        font-size: 0.41rem;
        font-weight: 400;
        color: #093c7f;
      }
      .gang {
        width: 0.8rem;
        height: 0.1rem;
        background: #093c7f;
      }
    }
    background: url(../../../assets/img/content8.0.jpg) no-repeat;
    background-size: cover; /* 让背景图基于容器大小伸缩 */
  }
}
@media only screen and (max-width: 19200px) and (min-width: 751px) {
  .content {
    width: 100%;
    .web {
      display: none;
    }
    a {
      cursor: pointer;
    }
    .content1 {
      height: 9.01rem;
      margin: auto;
      background-color: #f1f5fd;
      font-family: PingFang SC-Medium, PingFang SC;
      overflow: hidden;
      position: relative;
      p {
        height: 1.1rem;
        font-size: 0.64rem;
        font-weight: 500;
        color: #093c7f;
        line-height: 0.75rem;
        margin-top: 1.19rem;
        margin-left: 6.29rem;
      }
      .gang {
        width: 8.56rem;
        height: 0.1rem;
        background: #093c7f;
        position: absolute;
        left: 5.8rem;
      }
      img {
        width: 11rem;
        height: 4.39rem;
        margin-top: 0.72rem;
        margin-left: 4.5rem;
      }
    }
    .content2 {
      height: 10rem;
      margin: auto;
      background: url(../../../assets/img/content2.jpg) no-repeat;
      background-size: cover; /* 让背景图基于容器大小伸缩 */
    }
    .new3 {
      height: 9rem;
      position: relative;
      overflow: hidden;
      .img1 {
        position: absolute;
        width: 7.33rem;
        height: 6.44rem;
        top: 1.32rem;
        right: 2.58rem;
      }
      .new3-box {
        position: absolute;
        top: 3.06rem;
        left: 1.68rem;
        font-family: "微软雅黑";
        a {
          display: block;
          width: 4rem;
          height: 0.8rem;
          margin-top: 0.3rem;
          background: url(../../../assets/img/Component1.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        a:hover {
          background: url(../../../assets/img/Component2.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        p {
          font-size: 0.6rem;
          height: 0.84rem;
          font-weight: 800;
          color: #093c7f;
        }
        span {
          // font-family: "微软雅黑";
          display: block;
          width: 4.5rem;
          font-size: 0.2rem;
          // font-weight: 800;
          color: #093c7f;
        }
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
      }
      background: url(../../../assets/img/content9.0.jpg) no-repeat;
      background-size: cover; /* 让背景图基于容器大小伸缩 */
    }
    .content3 {
      height: 9rem;
      overflow: hidden;
      position: relative;
      margin: auto;
      .img1 {
        width: 9.22rem;
        height: 5.87rem;
        margin-top: 1.82rem;
        margin-left: 2.08rem;
      }
      .content3-box {
        position: absolute;
        top: 3.11rem;
        right: 1.54rem;
        font-family: "微软雅黑";
        a {
          display: block;
          width: 4rem;
          height: 0.8rem;
          margin-top: 0.3rem;
          background: url(../../../assets/img/Component1.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        a:hover {
          background: url(../../../assets/img/Component2.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        p {
          font-size: 0.6rem;
          height: 0.84rem;
          font-weight: 800;
          color: #093c7f;
        }
        span {
          // font-family: "微软雅黑";
          display: block;
          width: 4.5rem;
          font-size: 0.2rem;
          // font-weight: 800;
          color: #093c7f;
        }
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
      }

      background: url(../../../assets/img/content3.0.jpg) no-repeat;
      background-size: cover; /* 让背景图基于容器大小伸缩 */
    }
    .content4 {
      height: 9rem;
      position: relative;
      .img1 {
        position: absolute;
        width: 7.33rem;
        height: 6.44rem;
        top: 1.32rem;
        right: 2.58rem;
      }
      .content4-box {
        position: absolute;
        top: 3.06rem;
        left: 1.68rem;
        font-family: "微软雅黑";
        a {
          display: block;
          width: 4rem;
          height: 0.8rem;
          background-color: red;
          margin-top: 0.3rem;
          background: url(../../../assets/img/Component1.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        a:hover {
          background: url(../../../assets/img/Component2.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        p {
          font-size: 0.6rem;
          height: 0.84rem;
          font-weight: 800;
          color: #093c7f;
        }
        span {
          // font-family: "微软雅黑";
          display: block;
          width: 4.5rem;
          font-size: 0.2rem;
          // font-weight: 800;
          color: #093c7f;
        }
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
      }
      background: url(../../../assets/img/content4.0.jpg) no-repeat;
      background-size: cover; /* 让背景图基于容器大小伸缩 */
    }
    .content5 {
      height: 9rem;
      position: relative;
      overflow: hidden;
      .img1 {
        width: 5.68rem;
        height: 3.8rem;
        margin-top: 2.3rem;
        margin-left: 2.08rem;
      }
      .content5-box {
        position: absolute;
        top: 3.11rem;
        right: 1.54rem;
        font-family: "微软雅黑";
        a {
          display: block;
          width: 4rem;
          height: 0.8rem;
          margin-top: 0.3rem;
          background: url(../../../assets/img/Component1.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        a:hover {
          background: url(../../../assets/img/Component2.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        p {
          font-size: 0.6rem;
          height: 0.84rem;
          font-weight: 800;
          color: #093c7f;
        }
        span {
          // font-family: "微软雅黑";
          display: block;
          width: 4.6rem;
          font-size: 0.2rem;
          // font-weight: 800;
          color: #093c7f;
        }
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
      }
      background: url(../../../assets/img/content5.0.jpg);
      background-size: cover; /* 让背景图基于容器大小伸缩 */
    }
    .content6 {
      height: 9rem;
      position: relative;
      overflow: hidden;
      .img1 {
        width: 10.26rem;
        height: 5.77rem;
        position: absolute;
        top: 2.17rem;
        right: 0.65rem;
      }
      .content6-box {
        position: absolute;
        top: 3.06rem;
        left: 1.68rem;
        font-family: "微软雅黑";
        a {
          display: block;
          width: 4rem;
          height: 0.8rem;
          margin-top: 0.3rem;
          background: url(../../../assets/img/Component1.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        a:hover {
          background: url(../../../assets/img/Component2.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        p {
          font-size: 0.6rem;
          height: 0.84rem;
          font-weight: 800;
          color: #093c7f;
        }
        span {
          // font-family: "微软雅黑";
          display: block;
          width: 4.5rem;
          font-size: 0.2rem;
          // font-weight: 800;
          color: #093c7f;
        }
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
      }
      background: url(../../../assets/img/content6.0.jpg) no-repeat;
      background-size: cover; /* 让背景图基于容器大小伸缩 */
    }
    .content7 {
      height: 9rem;
      position: relative;
      overflow: hidden;
      .img1 {
        width: 8.61rem;
        height: 5.17rem;
        margin-top: 2.5rem;
        margin-left: 1.35rem;
      }
      .content7-box {
        position: absolute;
        top: 3.11rem;
        right: 1.54rem;
        font-family: "微软雅黑";
        a {
          display: block;
          width: 4rem;
          height: 0.8rem;
          margin-top: 0.3rem;
          background: url(../../../assets/img/Component1.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        a:hover {
          background: url(../../../assets/img/Component2.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        p {
          font-size: 0.6rem;
          height: 0.84rem;
          font-weight: 800;
          color: #093c7f;
        }
        span {
          // font-family: "微软雅黑";
          display: block;
          width: 4.5rem;
          font-size: 0.2rem;
          // font-weight: 800;
          color: #093c7f;
        }
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
      }
      background: url(../../../assets/img/content7.0.jpg) no-repeat;
      background-size: cover; /* 让背景图基于容器大小伸缩 */
    }
    .content8 {
      height: 9rem;
      position: relative;
      overflow: hidden;
      .img1 {
        width: 8.23rem;
        height: 6.75rem;
        position: absolute;
        top: 1.34rem;
        right: 1.33rem;
      }
      .content8-box {
        position: absolute;
        top: 3.06rem;
        left: 1.68rem;
        font-family: "微软雅黑";
        a {
          display: block;
          width: 4rem;
          height: 0.8rem;
          margin-top: 0.3rem;
          background: url(../../../assets/img/Component1.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        a:hover {
          background: url(../../../assets/img/Component2.png) no-repeat;
          background-size: cover; /* 让背景图基于容器大小伸缩 */
        }
        p {
          font-size: 0.6rem;
          height: 0.84rem;
          font-weight: 800;
          color: #093c7f;
        }
        span {
          // font-family: "微软雅黑";
          display: block;
          width: 4.5rem;
          font-size: 0.2rem;
          // font-weight: 800;
          color: #093c7f;
        }
        .gang {
          width: 0.8rem;
          height: 0.1rem;
          background: #093c7f;
        }
      }
      background: url(../../../assets/img/content8.0.jpg) no-repeat;
      background-size: cover; /* 让背景图基于容器大小伸缩 */
    }
  }
}
</style>
